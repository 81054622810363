import React from 'react';
import '../Styling/Services.css';

function Services() {
  return (
    <div className='services'>
        <p>Additional Services coming soon...</p>
    </div>
    
  )
}

export default Services