const Quotes = {
    0: {
        quote: 'Angel is great at what he does. He is professional, a great listener, and pays attention to detail. Loved working with him and loved the final result!',
        client: '- Arizona Youth Revival'
    },
    1: {
        quote: 'Angel was VERY patient throughout the process! It was perfect to be honest. Thank you for designing and changing literally everything I needed!',
        client: '- Damaris'
    },
}

export default Quotes